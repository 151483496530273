<template>
  <v-card elevation="5">
    <!-- <v-card-title :tag="'h1'">
      Acesso Rápido
    </v-card-title> -->

    <v-card-text class="padding-top: 16px;">
      <div class="acesso-rapido-boxs">
        <router-link :to="{ name: 'lancamentos', params: { type: 'R' } }" class="acesso-rapido-boxs__box acesso-rapido-boxs__box_receita">
          <span class="acesso-rapido-boxs__box_icon acesso-rapido-boxs__box_icon_receita">
            <v-icon :size="32">mdi-plus</v-icon>
          </span>

          <span class="acesso-rapido-boxs__box_text acesso-rapido-boxs__box_text_receita">Receita</span>
        </router-link>
        <router-link :to="{ name: 'lancamentos', params: { type: 'D' } }" class="acesso-rapido-boxs__box acesso-rapido-boxs__box_despesas">
          <span class="acesso-rapido-boxs__box_icon acesso-rapido-boxs__box_icon_despesas">
            <v-icon :size="32">mdi-plus</v-icon>
          </span>

          <span class="acesso-rapido-boxs__box_text acesso-rapido-boxs__box_text_receita">Despesa</span>
        </router-link>
        <router-link :to="{ name: 'lancamentos', params: { type: 'T' } }" class="acesso-rapido-boxs__box acesso-rapido-boxs__box_transferencia">
          <span class="acesso-rapido-boxs__box_icon acesso-rapido-boxs__box_icon_transferencia">
            <v-icon :size="32">mdi-swap-horizontal</v-icon>
          </span>

          <span class="acesso-rapido-boxs__box_text acesso-rapido-boxs__box_text_transfer">Transfer.</span>
        </router-link>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "GefinAcessoRapido"
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/helpers/_variables.scss';
.acesso-rapido-boxs {
  display: flex;

  &__box {
    width: 100px;
    height: 100px;
    background-color: #f5f5f5;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    text-decoration: none;

    &:hover {
      .acesso-rapido-boxs__box_icon {
        color: #FFF;
        border-color: #FFF;

        .v-icon {
          color: #FFF;
        }
      }

      .acesso-rapido-boxs__box_text {
        color: #FFF;
      }
    }


    &_receita:hover {
      background-color: $colorDanger;
    }

    &_despesas:hover {
      background-color: $colorGreenDarken;
    }

    &_transferencia:hover {
      background-color: $colorAccent;
    }

    &_icon {
      border: 2px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-bottom: 5px;
    }

    &_icon_transferencia {
      border-color: $colorAccent;
      .v-icon {
        color: $colorAccent;
      }
    }
    &_icon_receita {
      border-color: $colorDanger;
      .v-icon {
        color: $colorDanger;
      }
    }
    &_icon_despesas {
      border-color: $colorGreenDarken;
      .v-icon {
        color: $colorGreenDarken;
      }
    }

    &_text {
      color: #555;
      text-transform: uppercase;
    }
    &_text_ {
      text-transform: uppercase;
    }
    &_text {
      text-transform: uppercase;
    }
    &_text {
      text-transform: uppercase;
    }
  }
}
</style>